/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import "./src/tailwind.css";
import "./src/basic.css";
import "./src/fonts.css";

import { FontsLoader } from "./src/components/fonts";
import { UtmTracker } from "./src/components/tracking/utm-tracker";

// Fonts
window.__fonts = new FontsLoader(process.env.GATSBY_FONTS_PROJECT_ID);
window.__fonts.load();
// destroy fonts variable
window.__fonts = undefined;

// UTM Tracker
window.__utm = new UtmTracker(window.location.href);
