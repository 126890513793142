export class UtmTracker {
  #utm;
  #utmStorageKey;

  constructor(url, storageKey = "__utm") {
    this.#utmStorageKey = storageKey;
    this.generate(url);
  }

  generate(url) {
    this.load();

    if (this.#utm) {
      return;
    }

    this.generateFromUrl(url);
  }

  hasUtmParams(params) {
    const keys = Array.from(params.keys());
    return keys.some(key => key.startsWith("utm_"));
  }

  generateFromUrl(url) {
    const urlObj = new URL(url);
    const params = urlObj.searchParams;
    const entries = Array.from(params.entries());

    if (!this.hasUtmParams(params)) {
      return;
    }

    this.#utm = entries.reduce((a, [key, val]) => {
      if (!key.startsWith("utm_")) {
        return a;
      }

      a[key.substr("utm_".length)] = val;
      return a;
    }, {});

    this.persist();
  }

  load() {
    if (!window.sessionStorage) {
      return;
    }

    try {
      this.#utm = JSON.parse(
        window.sessionStorage.getItem(this.#utmStorageKey)
      );
    } catch (e) {
      window.sessionStorage.removeItem(this.#utmStorageKey);
    }
  }

  persist(utmParams) {
    if (!window.sessionStorage) {
      return;
    }

    window.sessionStorage.setItem(
      this.#utmStorageKey,
      JSON.stringify(this.utmMap())
    );
  }

  utmMap() {
    return {
      campaign: this.getCampaign(),
      source: this.getSource(),
      medium: this.getMedium(),
      term: this.getTerm(),
      content: this.getContent(),
    };
  }

  getCampaign() {
    return this.#utm?.campaign || null;
  }

  getSource() {
    return this.#utm?.source || null;
  }

  getMedium() {
    return this.#utm?.medium || null;
  }

  getTerm() {
    return this.#utm?.term || null;
  }

  getContent() {
    return this.#utm?.content || null;
  }
}
