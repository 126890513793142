export class FontsLoader {
  #projectId;

  constructor(projectId) {
    this.#projectId = projectId;
  }

  track() {
    // Necessary to make variable available for mtiFontTrackingCode.js
    window.MTIProjectId = this.#projectId;

    const mtiTracking = document.createElement("script");
    mtiTracking.type = "text/javascript";
    mtiTracking.async = "true";
    mtiTracking.src = "/mtiFontTrackingCode.js";
    (
      document.getElementsByTagName("head")[0] ||
      document.getElementsByTagName("body")[0]
    ).appendChild(mtiTracking);
  }

  load() {
    this.track();
  }
}
